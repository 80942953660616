@import url('https://fonts.googleapis.com/css?family=Comfortaa');

:root {
    --font-size: 1em;
    --font-primary: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    --font-secondary: Comfortaa, sans-serif;
    --font-tertiary: Arial, sans-serif;

    --color-action: #9b0ca5;
    --color-action-rgb: 155, 12, 165;
    --color-action-active: #f000ff;
    --color-action-active-rgb: 240, 0, 255;
    --color-action-button: #58005e;
    --color-action-button-rgb: 88, 0, 94;
    --color-action-text: #fff;
    --color-action-text-rgb: 255, 255, 255;

    --color-primary: #000;
    --color-primary-rgb: 255, 255, 255;
    --color-primary-text: #fff;
    --color-primary-text-rgb: 0, 0, 0;

    --color-secondary: #000;
    --color-secondary-rgb: 255, 255, 255;
    --color-secondary-text: #fff;
    --color-secondary-text-rgb: 0, 0, 0;

    --color-tertiary: #332e2e;
    --color-tertiary-rgb: 51, 46, 46;
    --color-tertiary-text: #f126ff;
    --color-tertiary-text-rgb: 85, 85, 85;

    --color-border: #9b0ca5;
    --color-border-rgb: 155, 12, 165;
    --color-border-dark: #505050;
    --color-border-dark-rgb: 80, 80, 80;
    --size-border: 2px;

    --color-menu: #58005e;
    --color-menu-rgb: 88, 0, 94;

    --color-card: #58005e;
    --color-card-rgb: 88, 0, 94;
    --color-card-text: #fff;

    --color-footer: #58005e;
    --color-footer-rgb: 88, 0, 94;

    --color-spinner: #58005e;
    --color-overlay: rgba(0, 0, 0, 0.7);
    --color-overlay-text: #fff;

    --color-join-bg: #eee;
    --color-join-item: #fff;
    --color-join-item-text: #000;
    --color-join-button: #9b0ca5;
    --color-join-button-text: #fff;
    --color-join-arrow: #9b0ca5;
    --color-join-active: #58005e;
    --color-privacy-text: #999;

    --padding-card: 0;
    --margin-card: 1em;

    --height-header: 3.5em;
}

@media (max-width: 64em) {
    :root {
        --margin-card: 0.3em;
    }
}

.button {
    border-radius: 1px;
}

.button a {
    color: #fff;
}

.menu {
    font-weight: 600;
}

.heading {
    color: var(--color-action-active);
}
