@import url(https://fonts.googleapis.com/css?family=Comfortaa);
:root {
    --font-size: 1em;
    --font-primary: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    --font-secondary: Comfortaa, sans-serif;
    --font-tertiary: Arial, sans-serif;

    --color-action: #9b0ca5;
    --color-action-rgb: 155, 12, 165;
    --color-action-active: #f000ff;
    --color-action-active-rgb: 240, 0, 255;
    --color-action-button: #58005e;
    --color-action-button-rgb: 88, 0, 94;
    --color-action-text: #fff;
    --color-action-text-rgb: 255, 255, 255;

    --color-primary: #000;
    --color-primary-rgb: 255, 255, 255;
    --color-primary-text: #fff;
    --color-primary-text-rgb: 0, 0, 0;

    --color-secondary: #000;
    --color-secondary-rgb: 255, 255, 255;
    --color-secondary-text: #fff;
    --color-secondary-text-rgb: 0, 0, 0;

    --color-tertiary: #332e2e;
    --color-tertiary-rgb: 51, 46, 46;
    --color-tertiary-text: #f126ff;
    --color-tertiary-text-rgb: 85, 85, 85;

    --color-border: #9b0ca5;
    --color-border-rgb: 155, 12, 165;
    --color-border-dark: #505050;
    --color-border-dark-rgb: 80, 80, 80;
    --size-border: 2px;

    --color-menu: #58005e;
    --color-menu-rgb: 88, 0, 94;

    --color-card: #58005e;
    --color-card-rgb: 88, 0, 94;
    --color-card-text: #fff;

    --color-footer: #58005e;
    --color-footer-rgb: 88, 0, 94;

    --color-spinner: #58005e;
    --color-overlay: rgba(0, 0, 0, 0.7);
    --color-overlay-text: #fff;

    --color-join-bg: #eee;
    --color-join-item: #fff;
    --color-join-item-text: #000;
    --color-join-button: #9b0ca5;
    --color-join-button-text: #fff;
    --color-join-arrow: #9b0ca5;
    --color-join-active: #58005e;
    --color-privacy-text: #999;

    --padding-card: 0;
    --margin-card: 1em;

    --height-header: 3.5em;
}

@media (max-width: 64em) {
    :root {
        --margin-card: 0.3em;
    }
}

.button {
    border-radius: 1px;
}

.button a {
    color: #fff;
}

.menu {
    font-weight: 600;
}

.heading {
    color: #f000ff;
    color: var(--color-action-active);
}

html,
body,
#root,
.app {
    height: 100%;
}

body {
    font-family: var(--font-primary);
    font-size: var(--font-size);
    background-color: var(--color-primary);
    color: var(--color-primary-text);

    margin: 0;
    font-display: block;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1 {
    text-transform: capitalize;
}

h1.tertiary-text {
    font-size: 2em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--font-secondary);
    font-weight: 400;
}

a {
    cursor: pointer;
    text-decoration: inherit;
    color: inherit;
    outline-color: var(--color-action-button);
}

@media (max-width: 64em) {
    h1 {
        font-size: 1.5em
    }

    h2 {
        font-size: 1.125em
    }

    h3 {
        font-size: 0.975em
    }

    h4 {
        font-size: 0.75em
    }

    h5 {
        font-size: 0.6em
    }

    h6 {
        font-size: 0.525em
    }
}

header {
    font-family: var(--font-secondary);
    display: block;
    text-transform: uppercase;
    background-color: var(--color-menu);
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    font-size: 1em;
    z-index: 1000;
    height: var(--height-header);
    line-height: var(--height-header);
}

header .header {
    max-width: 80em;
    /* 1280px */
    margin-left: auto;
    margin-right: auto;
}

main {
    margin-top: var(--height-header);
    margin-bottom: 0.5em;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

input[type="text"],
input[type="email"],
input[type="password"] {
    box-sizing: border-box;
    height: 2.4375rem;
    padding: 0.5rem;
    border: 0.15em solid var(--color-action-active);
    border-radius: 0.25em;
    font-family: Comfortaa, sans-serif;
    font-size: 1em;
    font-weight: 600;
    overflow: visible;

    background-color: var(--color-primary);
    color: var(--color-primary-text);
}

input[type="text"]:focus-visible,
input[type="email"]:focus-visible,
input[type="password"]:focus-visible {
    outline: 0px;
}

input[type="submit"] {
    cursor: pointer;
}

.page {
    margin-left: auto;
    margin-right: auto;
    max-width: 80em;
    box-sizing: border-box;
    padding-left: 1px;
    padding-right: 1px;
}

.blur {
    text-shadow: 0 0 10px rgba(var(--color-primary-text-rgb), 0.5);
    color: transparent !important;
}

.blur.action {
    text-shadow: 0 0 10px rgba(var(--color-action-text-rgb), 0.5);
}

.grecaptcha-badge {
    visibility: hidden;
}

#login .grecaptcha-badge {
    visibility: visible;
    position: fixed;
    bottom: 1em;
    right: 1em;
}

@media (max-width: 25em) {
    #login .grecaptcha-badge {
        visibility: visible;
        margin-left: auto;
        margin-right: auto;
        right: 1em;
        left: 1em;
        z-index: 5000;
    }
}

footer {
    color: var(--color-secondary-text);
    background-color: var(--color-secondary);
    padding: 1em;
}

footer a:hover {
    color: var(--color-action-button);
    text-decoration: underline;
}

#logo-image {
    margin-left: 4px;
    -webkit-transform: translateY(-6px);
            transform: translateY(-6px);
}

.logo a {
    text-decoration: none;
}

.action {
    cursor: pointer;
    text-transform: uppercase;
    color: var(--color-action-text);
    background-color: var(--color-action);
}

.badge {
    color: var(--color-action-text);
    background-color: var(--color-action-active);
}

.button-action {
    color: var(--color-action-text);
    background-color: var(--color-action-button);
}

.button {
    font-family: var(--font-secondary);
    padding-left: 0.5em;
    padding-right: 0.5em;
}

.button a {
    display: block;
    text-decoration: none;
}

.primary {
    background-color: var(--color-action);
}

.primary-text,
.primary-text a {
    color: var(--color-primary);
}

.secondary {
    background-color: var(--color-action-button);
}

.secondary-text,
.secondary-text a {
    color: var(--color-action-active);
    text-decoration: none;
}

.tertiary {
    background-color: var(--color-tertiary);
}

.tertiary-text {
    color: var(--color-tertiary-text);
}

.border {
    border: 1px solid var(--color-border);
    border: var(--size-border, 1px) solid var(--color-border);
}

.break {
    /* From: https://stackoverflow.com/a/33214667 */
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;
}

.card {
    background-color: var(--color-card);
    color: var(--color-card-text);
    padding: var(--padding-card);
    margin: var(--margin-card);
}

.heading {
    text-transform: uppercase;
}

.fade-exit {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0;
    transition: opacity 1s;
}

.fade-exit-done {
    opacity: 0;
}
